import { tw } from '@/utils/tw';
import { default as BaseVideoTranscriptTheme } from 'base/components/VideoTranscript/theme';

const VideoTranscript = tw.theme({
  extend: BaseVideoTranscriptTheme,
  slots: {
    title: ['text-preamble-sm', 'font-bold'],
    text: ['text-preamble-sm'],
    time: ['text-preamble-sm'],
  },
});

export default Object.assign(VideoTranscript, {});
